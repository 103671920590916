<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
          <h4>{{ Object.entries(this.data).length === 0 ? "AJOUTER UN" : "MODIFICATION DU" }} PACKS </h4>
          <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">

          <p>Packs <b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            name="name"
            v-model="input.name"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>

          <p > Type pack <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            @input="handleSelectionChange"
            name="type"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.type"
            :options="choicepack">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>

          <p class="pt-4"> Montant d'adhésion <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.amount"
            v-validate="'required'"
            name="amount"
            />
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first('amount') }}</span>

          <div v-if="input.type === 'individual'">
          <p class="pt-4"> Nombre de personne <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.numberOfPerson"
            disabled="true"
            v-validate="'required'"
            name="numberOfPerson"
            />
          <span class="text-danger text-sm" v-show="errors.has('numberOfPerson')">{{ errors.first('numberOfPerson') }}</span>
          </div>

          <div v-if="input.type === 'family'">
          <p class="pt-4"> Nombre de personne <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.numberOfPerson"
            v-validate="'required'"
            name="numberOfPerson"
            />
          <span class="text-danger text-sm" v-show="errors.has('numberOfPerson')">{{ errors.first('numberOfPerson') }}</span>
          </div>

          <p class="pt-4"> Durée (Année) <b style="color: #ff6141" >*</b> </p>
          <vs-input-number
            class="w-full ml-0"
            v-model="input.duration"
            v-validate="'required'"
            name="duration"
            />
          <span class="text-danger text-sm" v-show="errors.has('duration')">{{ errors.first('duration') }}</span>

          <p> Pays <b style="color: #ff6141" >*</b> </p>
          <v-select
            v-validate="'required'"
            name="country"
            :reduce="rep => rep.id"
            class="w-full" label="name"
            v-model="input.country"
            :options="countries">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('country')">{{ errors.first('country') }}</span>

        </div>
      </component>

      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="Prospect_validate">Soumettre</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Annuler</vs-button>
      </div>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

// For custom error message
import { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'Le champ nom du pack est obligatoire'
    },
    amount: {
      required: 'Le champ montant est obligatoire'
    },
    numberOfPerson: {
      required: 'Le champ nombre de personne est obligatoire'
    },
    duration: {
      required: 'Le champ durée est obligatoire'
    },
    country: {
      required: 'Le champ pays est obligatoire'
    },
    type: {
      required: 'Le champ type est obligatoire'
    }
  }
}

// register custom messages
Validator.localize('en', dict)

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

const input_tempon = {
  amount: 0,
  name: '',
  numberOfPerson: 0,
  duration: 0,
  country: '',
  type: ''
}

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  data () {
    return {
      choicepack: [
        {
          key: 'family',
          name: 'Familial'
        },
        {
          key: 'individual',
          name: 'Individuel'
        }
      ],
      packsnew:'',
      countries: [],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))

        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        window.getProspect.getAllPacks()
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    handleSelectionChange (selectedOption) {
      if (selectedOption === 'individual') {
        this.input.numberOfPerson = 1
      }
    },
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    Prospect_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))

      let url = 'packs/'
      let methods = 'post'
      const message = {
        error: 'Votre enrégistrement a échoué.',
        success: 'Le pack est enrégistrer avec succès.'
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = 'Le pack est modifié avec succès.'
      }

      this.$http[methods](url, input)
        .then((response) => {
          // this.countries = response.data
          window.getProspect.getAllPacks()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
          console.log('error: ', error)
          /* console.log('error.response.data: ', Object.keys(error.response.data))
          console.log('error.response: ', error.response) */

          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''

            if (item === 'amount') {
              libelle = 'Montant'
            } else if (item === 'name') {
              libelle = 'Nom du pack'
            } else if (item === 'numberOfPerson') {
              libelle = 'Nombre de personne'
            } else if (item === 'type') {
              libelle = 'Type de pack'
            } else if (item === 'duration') {
              libelle = 'Durée'
            }

            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }

          window.getPrendTaCom.error(message.error)
        })
    },
    updateCurrImg (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    getAllContries () {
      //delete this.$http.defaults.headers.common['Authorization']
      this.$http.get('countries/')
        .then((response) => {
          this.countries = response.data
        })
        .catch(() => {
        })
    }
  },
  async created () {
    this.getAllContries()
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

    &:not(.ps) {
      overflow-y: auto;
    }
}
</style>
